import React, {Component} from 'react';

export class Header extends Component{
    render(){
        return(
        <div className="header">
        </div>
        );
    }
}
export default Header;
