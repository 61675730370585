import React, {Component} from "react";
import logo from '../media/under_construction.png';
import '../styles/content.css';

export class Content extends Component {
  render() {
    return (
    <div>
    <p className="App-intro">Hello! we are under Construction here.</p>
    <img src={logo} className="App_construction_image" alt = "Logo" />
    <p className="App-intro">Please look for Us on Instagram.</p>
    </div>
    );
  }
}

export default Content;